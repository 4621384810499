<template>
  <Dialog v-model:visible="show" :header="form.id == null? '新增诉求':'编辑诉求'" @hide="$emit('close',true)">
    <div class="p-p-3">
      <div>
        <div class="p-grid p-col-12" style="padding: 0">
          <div class="p-col-6">
            <label style="display:inline-block;width:150px;">问题标题:</label>
            <InputText style="width: 100%" v-model="form.title" type="text"/>
          </div>

          <div class="p-col-6">
            <label style="display:inline-block;width:150px;">所属行业:</label>
            <Dropdown style="padding:0px; width: 100%" v-model="form.industry" :options="industry" option-label="name"
                      option-value="code"
                      placeholder="所属行业"/>
          </div>

          <div class="p-col-12">
            <label style="display:inline-block;width:150px;">诉求简介:</label>
            <Textarea :autoResize="true" style="width: 100%;" v-model="form.brief" type="text"/>
          </div>
        </div>

        <div style="padding: 0 14px 0 0">
          <label>问题描述</label>
          <div class="p-mt-2">
            <my-editor :value="form.content" v-on:input="setContent"></my-editor>
<!--            <Editor v-model="form.content" editorStyle="height: 180px"/>-->
          </div>
        </div>
        <div class="p-field p-d-flex p-ai-center">
          <div class="p-mr-2">需求是否有偿</div>
          <div class="p-col p-d-flex p-ai-center">
            <InputSwitch v-model="checked"/>
            <InputNumber v-if="checked===true" v-model="form.remuneration" class="p-ml-5" currency="CNY"
                         currencyDisplay="code"
                         locale="zh-CN" mode="currency"/>
          </div>
        </div>
        <div>
          <label>希望平台邀请谁参本次需求研讨与？：</label>
          <div class="p-d-flex p-mt-4">
            <div class="p-field-checkbox p-mr-2">
              <Checkbox id="city1" v-model="persons" name="city" value="行业专家"/>
              <label for="city1">行业专家</label>
            </div>
            <div class="p-field-checkbox p-mr-2">
              <Checkbox id="city2" v-model="persons" name="city" value="同行企业"/>
              <label for="city2">同行企业</label>
            </div>
            <div class="p-field-checkbox p-mr-2">
              <Checkbox id="city3" v-model="persons" name="city" value="案例提供方"/>
              <label for="city3">案例提供方</label>
            </div>
            <div class="p-field-checkbox p-mr-2">
              <Checkbox v-model="persons" name="city" value="企业"/>
              <label>企业</label>
            </div>
            <div class="p-field-checkbox p-mr-2">
              <Checkbox v-model="persons" name="city" value="主办方"/>
              <label>主办方</label>
            </div>
            <div class="p-field-checkbox p-mr-2">
              <Checkbox v-model="persons" name="city" value="平台所有人"/>
              <label>平台所有人</label>
            </div>
            <!--            <div class="p-field-checkbox p-mr-2">-->
            <!--              <Checkbox v-model="persons" name="city" value="指定专家"/>-->
            <!--              <label>指定专家</label>-->
            <!--              <MultiSelect v-model="selectedPerson" :options="person" optionLabel="brand" placeholder="选择品牌" />-->
            <!--            </div>-->
          </div>
        </div>
        <div class="p-mt-2 p-mb-2 p-grid p-jc-evenly">
          <Button @click="closeCPM" class="p-ml-5" label="取消发布"/>
          <Button @click="submit" :label="form.id==null?'确认发布需求到平台':'修改需求'"/>
        </div>
      </div>
    </div>
    <!--  </div>-->
    <Toast/>
  </Dialog>
</template>

<script>
import myEditor from "@/views/backend/myEditor";
export default {
  components:{myEditor},
  name: "RequirementsRelease",
  data() {
    return {

      show: false,
      form: {
        id: null,
        title: null,
        industry: null,
        content: null,
        remuneration: 0,
        type: null,
        brief: null
      },
      items: [
        {label: '业务需求管理', to: '/'},
        {label: '需求发布'}
      ],
      checked: false,
      persons: [],
      selectedPerson: null,
      person: [],
      industry: [],
    }
  },
  mounted() {
    this.$http.get('/dictionary/selectValue', {params: {key: 'suoshuhangye'}}).then((res) => {
      let list = res.data.myValue.split(",");
      let resList = []
      for (let i in list) {
        let item = {}
        item.name = list[i]
        item.code = list[i]
        resList.push(item)
      }
      this.industry = resList;
    })

    //this.getExpertList()
  },
  methods: {
    setContent(val){this.form.content = val},
    closeCPM() {
      this.show = false;
    },
    init(data) {
      if (data.id) {
        this.form = data
        let list = []
        list = data.type.split(',')
        this.persons = list
        if (data.remuneration == 0) {
          this.checked = false
        } else {
          this.checked = true
        }
      }
      this.show = true
    },
    close() {
      this.$emit('close', true)
    },
    // getExpertList() {
    //   this.$http.get('/expert/getAllExpertList').then((res) => {
    //     console.log(res.data)
    //   })
    // },
    submit() {
      let ths = this
      let str = ''
      let list = this.persons
      for (let i = 0; i < list.length; i++) {
        if (i < list.length - 1) {
          str = str + list[i] + ','
        } else {
          str = str + list[i]
        }
      }
      this.form.type = str
      this.$http.post('/demands/addDemands', this.form).then(() => {
        this.$toast.add({severity: 'success', summary: '成功', life: 3000})
        ths.show = false
      })
    }
  }
}
</script>

<style scoped>

</style>
